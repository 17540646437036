<template>
  <div>
    <a
      href="javascript:void(0)"
      class="h5"
      :class="cierreConfirmado? 'text-light':'text-skyblue'"
      @click="openModalServicios"
      :disabled="cierreConfirmado">
      <b>Agregar Servicio</b>
    </a>
    <b-modal id="modal-servicios"
     v-model="modalShow"
     size="lg"
     title="Agregar Servicio"
     @ok="agregarServicio"
     @hidden="resetearModal">
      <div class="row mb-2">
        <div class="col-sm-6">
          <div class="form-group mb-0">
            <label class="col-form-label">Grupo de Servicio:</label>
            <multiselect v-model="categoriaServiciosSelected"
              :options="getCategoriaServicios"
              @input="actualizarListaServicios"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(categoriaServiciosSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-6" v-if="serviciosSelected.length < 2">
          <div class="form-group">
            <label class="col-form-label" style="user-select: none"><br></label>
            <div
              v-if="aseguradoraSelected !== null && listaPrecioSeguros.length !== 0"
              class="row mb-2"
            >
              <div class="col-sm-4 text-right pl-0">
                <label class="col-form-label">
                  Precio del Seguro:
                </label>
              </div>
              <div class="col-sm-4 px-0">
                <multiselect id="comboPrecioSeguros"
                  v-model="precioSeguroSelected"
                  :options="listaPrecioSeguros"
                  @input="actualizarInputPrecioSeguro"
                  placeholder=""
                  label="nombre" track-by="id"
                  select-label=""
                  deselect-label="X">
                  <span slot="caret" v-if="!(precioSeguroSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                  <template slot="singleLabel" slot-scope="props">
                    <small>{{props.option.nombre}}</small>
                  </template>
                  <template slot="option" slot-scope="props">
                    <small>{{props.option.nombre}}</small>
                  </template>
                </multiselect>
              </div>
              <div class="col-sm-4">
                <input type="text"
                  class="form-control"
                  :value="inputPrecioSeguro | numeral('0,0.00')"
                  autocomplete="off"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="col-form-label">Servicio:</label>
            <multiselect ref="comboServices"
              v-model="serviciosSelected"
              :options="listaServiciosInteligente"
              :multiple="true"
              :loading="loaderComboServicio"
              @select="agregarItemListaTemporal"
              @remove="eliminarItemListaTemporal"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
                <span slot="caret" v-if="!(serviciosSelected.length===0)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-6" v-if="serviciosSelected.length < 2">
          <div class="form-group">
            <label class="col-form-label" style="user-select: none"><br></label>
            <div class="row mb-2" v-if="getPrecios.length !== 0 && precioSeguroSelected === null">
              <div class="col-sm-4 text-right">
                <label class="col-form-label">
                  Lista de Precios:
                </label>
              </div>
              <div class="col-sm-4 px-0">
                <multiselect v-model="precioSelected"
                :options="getPrecios"
                @input="actualizarPrecioDelModal"
                :allow-empty="false"
                placeholder="Seleccione Precio"
                label="descuento" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                </multiselect>
              </div>
              <div class="col-sm-4">
                <input type="text"
                class="form-control"
                :value="precioModal| numeral('0,0.00')"
                autocomplete="off" disabled>
              </div>
            </div>
            <div class="row" v-if="getPrecios.length !== 0">
              <div class="col-sm-4 offset-sm-4 px-0">
                <div class="form-group">
                  <label class="label-control">Cantidad</label>
                  <input type="text" ref="inputCantidad"
                    v-model="cantidad"
                    class="form-control"
                    autocomplete="off"
                    @keyup.enter="agregarServicio"
                    @keydown="validarCaracter"
                    @blur="reValidarCantidad">
                </div>
              </div>
              <div class="col-sm-4" v-if="precioSeguroSelected === null">
                <div class="form-group">
                  <label class="label-control">Precio Manual</label>
                  <vue-numeric class="form-control"
                    v-model="precioUModal"
                    :disabled="isBloqueadoPrecioVenta"
                    @input="actualizarPrecioModal"
                    @keyup.native.enter="agregarServicio"
                    separator=","
                    :precision="2"
                    placeholder=" "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-sm-6">
          <div class="form-group">
            <label class="col-form-label" style="user-select: none"><br></label>
            <div class="row">
              <div class="col-sm-2 text-right">
                <label class="col-form-label"><b>Total:</b></label>
              </div>
              <div class="col-sm-3 px-0">
                <input type="text"
                class="form-control"
                :value="getSubTotalModal | numeral('0,0.00')"
                disabled>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-outline-dark" @click="cancel()">
          Cancelar
        </button>
        <button class="btn btn-warning" @click="ok()" :disabled="isDisabledAddButton">
          Agregar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { util } from '@/plugins/util';

export default {
  name: 'AddServiceModal',
  components: {
    Multiselect,
    VueNumeric,
  },
  created() {
    this.getServiciosApi();
  },
  data() {
    return {
      precioUModal: 0,
      loaderComboServicio: false,
      modalShow: false,
    };
  },
  methods: {
    openModalServicios() {
      if (this.cierreConfirmado) {
        return;
      }
      if (this.checkSeguroEstado && this.aseguradoraSelected === null) {
        util.showNotify('Elija un Seguro Medico.', 'warn');
        return;
      }
      this.$bvModal.show('modal-servicios');
    },
    actualizarListaServicios() {
      this.setServicios([]);
      this.setPrecios([]);
      this.listaPrecioSeguros = [];
      if (this.categoriaServiciosSelected !== null) {
        this.getServiciosApi();
      } else {
        this.serviciosSelected = [];
        this.setListaTemporal([]);
        this.setListaDetalleTemporal([]);
      }
    },
    async agregarItemListaTemporal(el) {
      this.loaderComboServicio = true;
      this.setItemListaTemporal(el);
      await this.getPreciosServicio();
      if (this.getListaTemporal.length < 2) {
        await this.getServiciosApi();
      }
      this.loaderComboServicio = false;
      if (this.modalShow) {
        this.$refs.comboServices.$el.focus();
      }
    },
    eliminarItemListaTemporal(el) {
      this.deleteItemListaTemporal(el.id);
      this.deleteItemListaDetalleTemporal(el.id);
      if (this.getListaTemporal.length === 0) {
        this.setPrecios([]);
        this.listaPrecioSeguros = [];
        if (this.getListaTabla.length === 0) {
          this.setActividadEconomicaId(0);
          this.setDocumentoSectorId(null);
          this.setSiatTipoFacturaId(null);
          this.getServiciosApi();
        }
      }
      if (this.getListaTemporal.length === 1) {
        this.deleteLastListaDetalleTemporal();
        this.getPreciosServicio();
      }
      this.$refs.comboServices.$el.focus();
    },
    precioUnitario() {
      if (this.precioSeguroSelected !== null) {
        return parseFloat(this.inputPrecioSeguro).toFixed(2);
      }
      return parseFloat(this.precioModal).toFixed(2);
    },
    precioTotal() {
      if (this.precioSeguroSelected !== null) {
        return this.cantidad * (
          parseFloat(this.inputPrecioSeguro) + parseFloat(this.precioSeguroSelected.seguro)
        );
      }
      return this.cantidad * parseFloat(this.precioModal);
    },
    actualizarInterfaz() {
      this.actualizarTotalSeguro();
      this.actualizarSubTotal();
      this.getServiciosApi();
      if (this.getModoEdicion === false) {
        if (this.getCheckFacturaTercero === false) {
          this.agregarMedioPagoContadoPorDefecto();
          this.setInsigniasFormaPagoContadoDefault();
        } else {
          this.setClassButton('btn-success');
          this.setInsigniasFormaPagoFacturacionTercero();
        }
      }
      if (this.nroCajas > 1) {
        this.setClassButton('btn-danger');
        this.nroCajas = 1;
        this.modalComboSelected2 = null;
        this.modalComboSelected3 = null;
        this.monto2 = 0;
        this.monto3 = 0;
        this.resetAjustesModal();
        this.listaBadges = [];
      }
    },
    agregarServicio(bvModalEvt) {
      if (this.getServiciosSelected.length === 0) {
        util.showNotify('Elije un Servicio', 'warn');
        bvModalEvt.preventDefault();
        return;
      }
      if (this.cantidad <= 0) {
        util.showNotify('Ingrese un Cantidad Mayor a 0!', 'warn');
        bvModalEvt.preventDefault();
        return;
      }
      this.precioModal = this.precioModal.toString().trim();
      if (this.precioModal === '') {
        util.showNotify('Precio no Valido', 'warn');
        return;
      }
      if (this.checkValorCero && parseInt(this.precioUnitario(), 10) !== 0) {
        util.showNotify('El CoaSeguro debe ser igual a 0', 'warn');
        return;
      }
      if (this.getListaDetalleTemporal.length === 1) {
        this.getListaDetalleTemporal.forEach((servicioTemporal) => {
          let seguro = '0.00';
          if (this.precioSeguroSelected !== null) {
            seguro = this.precioSeguroSelected.seguro;
          }
          /*
            para modificar item tambien modificar el itemServicio en addServiceModal.js en la funcion getPreciosServicio,
            ya que los 2 objetos estan relacionados. es decir si agrego alguna propiedad o quito tambien hacerlo en los 2 lados.
          */
          let siatActividadEconomicaId = servicioTemporal.siat_actividad_economica_id;
          let siatDescripcionService = servicioTemporal.siat_descripcion_service;
          let siatDescripcionUnidadMedida = servicioTemporal.siat_descripcion_unidad_medida;
          let siatTipoProductoServiceId = servicioTemporal.siat_tipo_producto_service_id;
          let siatUnidadMedidaId = servicioTemporal.siat_unidad_medida_id;
          const medico = this.getMedicoSelected;
          if (medico) {
            if (medico.configuracion_factura_tercero === 0) {
              siatActividadEconomicaId = medico.siat_actividad_economica_id;
              siatDescripcionService = medico.siat_nombre_producto_servicio;
              siatDescripcionUnidadMedida = medico.siat_nombre_unidad_medida;
              siatTipoProductoServiceId = medico.siat_producto_servicio_id;
              siatUnidadMedidaId = medico.siat_unidad_medida_id;
            }
          }
          const item = {
            numero: servicioTemporal.numero,
            servicio_id: servicioTemporal.servicio_id,
            descripcion: servicioTemporal.descripcion,
            medicoTempSelected: null,
            cantidad: this.cantidad,
            precio_unitario: this.precioUnitario(),
            total: this.precioTotal(),
            servicio: servicioTemporal.servicio,
            /* desc: '-', */
            seguro,
            fecha_turno: null,
            hora_turno: null,
            numero_ticket: null,
            numero_turno: null,
            turno_medico_id: null,
            siat_actividad_economica_id: siatActividadEconomicaId,
            siat_descripcion_service: siatDescripcionService,
            siat_descripcion_unidad_medida: siatDescripcionUnidadMedida,
            siat_tipo_producto_service_id: siatTipoProductoServiceId,
            siat_unidad_medida_id: siatUnidadMedidaId,
            idRow: servicioTemporal.idRow,
          };
          this.addItemlistaTabla(item);
        });
      } else {
        this.getListaDetalleTemporal.forEach((servicioTemp) => {
          this.addItemlistaTabla(servicioTemp);
        });
      }
      this.setListaTemporal([]);
      this.setListaDetalleTemporal([]);
      this.actualizarInterfaz();
    },
    resetearModal() {
      this.resetModalWhenClosed();
      this.precioUModal = 0;
    },
    actualizarPrecioInput() {
      this.precioModal = this.precioSelected.monto;
    },
    actualizarPrecioDelModal() {
      this.actualizarPrecioInput();
      this.precioUModal = 0;
    },
    actualizarPrecioModal() {
      if (this.precioUModal === '') {
        this.getPreciosServicio();
      } else {
        this.precioSelected = null;
        this.precioModal = this.precioUModal;
      }
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCantidad() {
      if (this.cantidad === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.cantidad)) {
        this.cantidad = '';
      }
    },
    actualizarInputPrecioSeguro() {
      if (this.precioSeguroSelected !== null) {
        this.inputPrecioSeguro = this.precioSeguroSelected.monto;
      } else {
        this.inputPrecioSeguro = 0;
      }
    },
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
      'setDocumentoSectorId',
      'setSiatTipoFacturaId',
      'setServicios',
      'setListaTemporal',
      'setListaDetalleTemporal',
      'setItemListaTemporal',
      'deleteItemListaTemporal',
      'deleteItemListaDetalleTemporal',
      'deleteLastListaDetalleTemporal',
      'setPrecios',
    ]),
    ...mapMutations('tableAndAddService', [
      'addItemlistaTabla',
    ]),
    ...mapMutations('formaPagoModal', [
      'agregarMedioPagoContadoPorDefecto',
      'setClassButton',
    ]),
    ...mapActions('formaPagoModal', [
      'resetDatosFormaPagoModal',
      'resetAjustesModal',
    ]),
    ...mapActions('detalleFactura', [
      'setInsigniasFormaPagoContadoDefault',
      'setInsigniasFormaPagoFacturacionTercero',
    ]),
    ...mapActions('addServiceModal', [
      'getServiciosApi',
      'getPreciosServicio',
      'resetModalWhenClosed',
    ]),
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
    ]),
  },
  computed: {
    ...mapFields('addServiceModal', [
      'categoriaServiciosSelected',
      'serviciosSelected',
      'precioSelected',
      'precioModal',
      'cantidad',
      'isDisabledAddButton',
      'precioSeguroSelected',
      'listaPrecioSeguros',
      'inputPrecioSeguro',
    ]),
    ...mapFields('formFactura', [
      'checkSeguroEstado',
      'aseguradoraSelected',
      'checkValorCero',
    ]),
    ...mapFields('modalCierreTratamiento', [
      'cierreConfirmado',
    ]),
    ...mapFields('formaPagoModal', [
      'nroCajas',
      'modalComboSelected2',
      'modalComboSelected3',
      'monto2',
      'monto3',
    ]),
    ...mapFields('detalleFactura', [
      'listaBadges',
    ]),
    listaServiciosInteligente() {
      if (this.precioSeguroSelected !== null) {
        return [];
      }
      return this.getServicios;
    },
    ...mapGetters('main', [
      'isBloqueadoPrecioVenta',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
    ...mapGetters('formFactura', [
      'getMedicoSelected',
      'getMedicos',
      'getCheckFacturaTercero',
    ]),
    ...mapGetters('tableAndAddService', [
      'getListaTabla',
    ]),
    ...mapGetters('addServiceModal', [
      'getCategoriaServicios',
      'getServiciosSelected',
      'getServicios',
      'getListaTemporal',
      'getListaDetalleTemporal',
      'getPrecios',
      'getSubTotalModal',
    ]),
  },
};
</script>

<style scoped>
.text-skyblue
{
  color: #61c6f2;
}
a.text-skyblue:hover{
  color:#1565c0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
