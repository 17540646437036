<template>
  <div class="row mb-4">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="th-custom-color" width="6%">Cantidad</th>
          <th class="th-custom-color text-center" width="20%">Servicio</th>
          <th class="th-custom-color text-center" width="20%">Descripcion</th>
          <th class="th-custom-color text-center" width="17%">Medico Tratante</th>
          <th class="th-custom-color text-center" width="6%"></th>
          <th class="th-custom-color text-center" width="8%" v-if="isDisableSeguro">CoaSeguro</th>
          <th class="th-custom-color text-center" width="8%" v-if="isDisableSeguro">Seguro</th>
          <th class="th-custom-color text-center" width="6%">P/U</th>
          <th class="th-custom-color" width="5%">Total</th>
          <th class="th-custom-color text-center" width="3%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listaTabla" :key="item.idRow">
          <td>{{ item.cantidad }}</td>
          <td class="text-center">({{item.numero}}) {{item.servicio}}</td>
          <td class="text-center">
            <input type="text"
              class="form-control"
              :value="item.descripcion"
              @input="actualizarDescripcionItem($event, index)"
            >
          </td>
          <td class="text-center">
            <div class="row">
              <div class="col-sm-11 pr-1">
                <multiselect v-model="item.medicoTempSelected"
                  :options="getMedicos"
                  @input="() => {
                    if(item.medicoTempSelected === null)
                    {
                      item.medico_id = null;
                    } else {
                      item.medico_id = item.medicoTempSelected.id
                    }
                  }"
                  placeholder=""
                  label="nombre"
                  track-by="id"
                  select-label=""
                  deselect-label="X">
                  <span slot="caret" v-if="!(item.medicoTempSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <template slot="singleLabel" slot-scope="props">
                    <small>{{props.option.nombre}}</small>
                  </template>
                  <template slot="option" slot-scope="props">
                    <small>{{props.option.nombre}}</small>
                  </template>
                </multiselect>
              </div>
              <div class="col-sm-1 pt-2 px-0" v-if="index === 0">
                <svg xmlns="http://www.w3.org/2000/svg"
                  @click="replicarMedicos(item)"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-lightning-fill text-warning accion-rapida-medico"
                  viewBox="0 0 16 16">
                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                    .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
                </svg>
              </div>
            </div>
          </td>
          <td class="text-center">
            <ModalTurno
              v-if="cierreConfirmado === false"
              :id="item.idRow"
              :key="item.idRow"
              :editMode="getModoEdicion"
              :medicoSelected="item.medicoTempSelected"
              @getData="getTurnoData(index, $event)"
              :turnoFromEdit="item.numero_turno"
              :fechaTurnoFromEdit="item.fecha_turno"
              :horaTurnoFromEdit="item.hora_turno"
              :numeroTicketFromEdit="item.numero_ticket"
            />
            <button v-else class="btn px-2 btn-light">
              Turno
            </button>
          </td>
          <td class="text-center" v-if="isDisableSeguro">
            {{
              ((parseFloat(item.seguro) > 0)? item.precio_unitario : 0) | numeral('0,0.00')
            }}
          </td>
          <td class="text-center" v-if="isDisableSeguro">
            <span class="text-success font-weight-bold">
              {{item.seguro | numeral('0,0.00')}}
            </span>
          </td>
          <td class="text-center">
            {{
              ((parseFloat(item.seguro) > 0)? 0 : item.precio_unitario) | numeral('0,0.00')
            }}
          </td>
          <td>{{item.total | numeral('0,0.00')}}</td>
          <td class="text-center">
            <i class="nav-icon i-Close-Window text-danger font-weight-bold"
              @click="eliminarItem(index)"
              style="cursor: pointer"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12">
      <AddServiceModal />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import privilegios from '@/plugins/privilegios/index';
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ModalTurno from './ModalTurno.vue';
import AddServiceModal from './AddServiceModal.vue';

export default {
  name: 'TableAndAddService',
  components: {
    AddServiceModal,
    Multiselect,
    ModalTurno,
  },
  data() {
    return {
      isDisableSeguro: false,
    };
  },
  mixins: [privilegios],
  created() {
    const funcionalidadId = this.funcionalidadAccions.SEG_LISTAR.funcionalidad_id;
    const funcionalidadAccion = this.funcionalidadAccions.SEG_LISTAR.accion;
    this.isDisableSeguro = this.existePrivilegios(funcionalidadId, funcionalidadAccion);
  },
  methods: {
    actualizarDescripcionItem(e, indice) {
      this.listaTabla[indice].descripcion = e.target.value;
    },
    eliminarItem(index) {
      this.cierreConfirmado = false;
      if (this.getCheckFacturaTercero) {
        this.setClassButton('btn-danger');
        this.resetFormaPagoFacturaTercero();
        this.listaBadges = [];
      }
      if (this.nroCajas > 1) {
        this.setClassButton('btn-danger');
        this.nroCajas = 1;
        this.modalComboSelected2 = null;
        this.modalComboSelected3 = null;
        this.monto2 = 0;
        this.monto3 = 0;
        this.listaBadges = [];
      }
      this.resetAjustesModal();
      this.listaTabla.splice(index, 1);
      this.actualizarTotalSeguro();
      this.actualizarSubTotal();
      if (this.listaTabla.length === 0) {
        this.setActividadEconomicaId(0);
        this.setDocumentoSectorId(null);
        this.setSiatTipoFacturaId(null);
        this.getServiciosApi();
      }
    },
    replicarMedicos(medicoItem) {
      this.listaTabla.forEach((item, index) => {
        this.listaTabla[index].medicoTempSelected = medicoItem.medicoTempSelected;
        if (medicoItem.medicoTempSelected === null) {
          this.listaTabla[index].medico_id = null;
        } else {
          this.listaTabla[index].medico_id = medicoItem.medicoTempSelected.id;
        }
      });
    },
    getTurnoData(index, data) {
      this.listaTabla[index].fecha_turno = data.fecha_turno;
      this.listaTabla[index].hora_turno = data.hora_turno;
      this.listaTabla[index].numero_ticket = data.numero_ticket;
      this.listaTabla[index].turno_medico_id = data.turno_medico_id;
    },
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
      'setDocumentoSectorId',
      'setSiatTipoFacturaId',
    ]),
    ...mapMutations('formaPagoModal', [
      'setClassButton',
    ]),
    ...mapActions('addServiceModal', [
      'getServiciosApi',
    ]),
    ...mapActions('formaPagoModal', [
      'resetAjustesModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
    ]),
  },
  computed: {
    ...mapFields('modalCierreTratamiento', [
      'cierreConfirmado',
    ]),
    ...mapFields('tableAndAddService', ['listaTabla']),
    ...mapFields('formaPagoModal', [
      'nroCajas',
      'modalComboSelected2',
      'modalComboSelected3',
      'monto2',
      'monto3',
    ]),
    ...mapFields('detalleFactura', [
      'listaBadges',
    ]),
    ...mapFields('main', [
      'funcionalidadAccions',
    ]),
    ...mapGetters('formFactura', [
      'getMedicos',
      'getCheckFacturaTercero',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
  },
};
</script>

<style scoped>
.accion-rapida-medico {
  cursor: pointer;
}
.accion-rapida-medico:active {
  color: orange !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
