<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Nueva Venta</h1>
        </div>
      </div>
      <transition name="fade">
        <div v-if="showCortinaInformativa"
         class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div
              v-if="parseFloat(getPacienteToShow.tratamiento) !== 0
              && getPacienteToShow.tratamiento !== null
              && parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-3 pt-2 pb-1 bg-yellow-custom
              text-center border-right border-white">
              Total Tratamiento: <br>
              {{getPacienteToShow.tratamiento | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-3"></div>
            <div
              v-if="parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-2 pt-2 pb-1 bg-yellow-custom text-center
              border-left border-right border-white">
              Tiene Anticipo: <br>
              {{getPacienteToShow.anticipo | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-2"></div>
            <div class="col-sm-3 py-2 bg-sky-light text-white border-left border-white">
              Edad: {{getPacienteToShow.edad}} años
            </div>
            <div class="col-sm-4 py-2 bg-sky-light text-white">
              Cobertura: {{getPacienteToShow.cobertura}}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <button type="submit"
                @click="guardarFormulario"
                class="btn bg-custom-green mr-2 rounded"
                :disabled="buttonDisabled">
                  Guardar
                </button>
                <router-link :to="{ name: 'facturacion-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
              <div class="col-sm-4 factura-contingencia">
                <notificacionContingency
                :contenido="tipoContingenciaSelected"
                :tipoContingencias="tipoContigenciasComputed"
                @_selectMotivoContingencia="selectMotivoContingencia"
                />
              </div>
              <div class="col-sm-3">
                <div
                  class="form-group row"
                  v-if="extrafacturaElectronica && (isTipoFacturaComputarizadaEnLinea || isTipoFacturaElectronicaEnLinea)"
                >
                  <div class="col-sm-12 text-center" v-for="(tipoEmision, index) in getTipoEmisionsComputed" :key="index">
                    <label v-show="tipoEmisionSelected.id == tipoEmision.id">
                        {{ tipoEmisionSelected.descripcion }}
                    </label>
                    <i class="i-Record-2 en-linea"
                        v-show="tipoEmisionSelected.id == tipoEmision.id"
                        @click="showModalFacturaContigencia(index + 1)"
                        :style="colorIconEstadoFactura(tipoEmisionSelected.cod_clasificador)">
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- contiene formulario para factura -->
            <FormFactura />
            <!-- Tabla y enlace Agregar Servicio-->
            <TableAndAddService />
            <!-- detalles finales de factura -->
            <DetalleFactura />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { FORMAS_PAGO } from '@/utils/globalConventions';
import FormFactura from '../components/TheFormFactura.vue';
import TableAndAddService from '../components/SectionTableAndAddService.vue';
import DetalleFactura from '../components/TheDetalleFactura.vue';
import notificacionContingency from '../components/notificacionContingency.vue';
import { util } from '../../../plugins/util';

export default {
  name: 'create',
  components: {
    FormFactura,
    TableAndAddService,
    DetalleFactura,
    notificacionContingency,
  },
  async created() {
    this.asignarfechaCreditoVencimiento();
    await this.callCreateApi();
    this.isLoading = false;
  },
  beforeDestroy() {
    this.resetState();
  },
  data() {
    return {
      isLoading: true,
      buttonDisabled: false,
    };
  },
  methods:
  {
    redirect(path) {
      this.$router.push(path);
    },
    async guardarFormulario() {
      if (this.getPacienteSelected === null) {
        util.showNotify('Elija un Paciente', 'warn');
        return;
      }
      if (this.isEnabledFormaPago === false) {
        util.showNotify('Ingrese forma de pago!', 'warn');
        return;
      }
      if (this.checkSeguroEstado && parseFloat(this.totalSeguro) <= 0) {
        util.showNotify('Ingrese un servicio con seguro!', 'warn');
        return;
      }
      if (this.extrafacturaElectronica) {
        if (this.isTipoFacturaComputarizadaEnLinea || this.isTipoFacturaElectronicaEnLinea) {
          if (this.conexionSiat) {
            // modalidad de facturador
            if (!this.getSucursal.siat_sucursal_id) {
              util.showNotify('La sucursal Seleccionada no esta sincronizada con facturador', 'warn');
              return;
              // eslint-disable-next-line no-else-return
            } else {
              const puntoVentaFiltered = this.getPuntoVentas.filter((el) => el.siat_sucursal_id === this.getSucursal.siat_sucursal_id);
              if (puntoVentaFiltered.length === 0) {
                util.showNotify('La sucursal Seleccionada no esta vinculado con un punto de venta', 'warn');
                return;
              }
            }

            let isValidListaServicios = true;
            this.getListaTabla.forEach((element, index) => {
              if (!element.siat_actividad_economica_id) {
                util.showNotify(`La actividad economica del servicio de la fila ${index + 1} no esta vinculado con un parametro siat`, 'warn');
                isValidListaServicios = false;
              }
              if (!element.siat_tipo_producto_service_id) {
                util.showNotify(`El servicio de la fila ${index + 1} no esta vinculado a un producto/servicio siat`, 'warn');
                isValidListaServicios = false;
              }
              if (!element.siat_unidad_medida_id) {
                util.showNotify(`La unidad de medida del servicio de la fila ${index + 1} no esta vinculado con un parametro con siat `, 'warn');
                isValidListaServicios = false;
              }
            });
            if (isValidListaServicios === false) {
              return;
            }
            if (!this.monedaSelected.siat_moneda_secundaria_id && !this.monedaSelected.siat_moneda_principal_id) {
              util.showNotify('El tipo de moneda no esta vinculado con un parametro siat', 'warn');
              return;
            }
            if (!this.indentificacionSelected.tipo_documento_id_siat) {
              util.showNotify('El tipo de documento no esta vinculado con un parametro siat', 'warn');
              return;
            }
            if (this.getMedioPago && !this.getMedioPago.siat_tipo_medio_pago_id) {
              util.showNotify('El medio de pago no esta vinculado con un parametro siat!', 'warn');
              return;
            }
            if (this.getFormaPago.tipo_forma_pago_id === FORMAS_PAGO.CREDITO) {
              if (!this.medioPagoCredito || !this.medioPagoCredito.siat_tipo_medio_pago_id) {
                util.showNotify('La forma de pago no esta vinculado con un parametro siat!', 'warn');
                return;
              }
            }
            if ((this.checkCXP) && (this.isAjuste)) {
              if (!this.medioPagoAjusteAnticipo || !this.medioPagoAjusteAnticipo.siat_tipo_medio_pago_id) {
                util.showNotify('La forma de pago no esta vinculado con un parametro siat!', 'warn');
                return;
              }
            }
            if (this.tipoEmisionSelected.cod_clasificador === 2 /* Fuera de Linea */) {
              if (!this.tipoContingenciaSelected) {
                util.showNotify('Debe Seleccionar un tipo de contingencia', 'warn');
                return;
              }
            }
            if (!this.getCompany.nit) {
              util.showNotify('El nit de la empresa es requerido en sus datos de empresa', 'warn');
              return;
            }
            if (!this.getCompany.business_name) {
              util.showNotify('La razon social de la empresa es requerido en sus datos de empresa', 'warn');
              return;
            }
          } else {
            util.showNotify('Revise su conexion con Siat Facturador', 'warn');
            return;
          }
        }
      }
      if (this.checkFacturaTercero && this.isTipoFacturaManual) {
        if (this.nroFactura === null || this.nroFactura === '') {
          util.showNotify('Indique el numero de la Factura', 'warn');
          return;
        }
      }
      if (this.caja + this.banco + this.tarjeta > this.getSubTotal) {
        util.showNotify('el monto de pago es superior al subTotal', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const { STORE_OK, ventaId } = await this.callStoreApi();
      if (STORE_OK) {
        this.redirect(this.getPathForRedirect(ventaId));
      }
      this.buttonDisabled = false;
    },
    getPathForRedirect(ventaId) {
      if (this.isTipoFacturaComputarizada) {
        return { name: 'facturacion-print', params: { id: btoa(ventaId) } };
      }
      return { name: 'facturacion-index' };
    },
    asignarfechaCreditoVencimiento() {
      const fechaTemp = new Date();
      const year = fechaTemp.getFullYear();
      const month = fechaTemp.getMonth() + 1;
      const day = fechaTemp.getDate();
      this.setFechaCreditoVencimiento(`${day}/${month}/${year}`);
    },
    resetState() {
      this.resetFacturacion();
      this.resetFormFactura();
      this.resetTableAndAddService();
      this.resetAddServiceModal();
      this.resetFormaPagoModal();
      this.resetFormaPagoFacturaTercero();
      this.resetDetalleFactura();
    },
    showModalFacturaContigencia(index) {
      this.tipoContingenciaSelected = null;
      if (index < this.getTipoEmisionsComputed.length) {
        this.tipoEmisionSelected = this.getTipoEmisionsComputed[index];
      } else {
        [this.tipoEmisionSelected] = this.getTipoEmisionsComputed;
      }
    },
    colorIconEstadoFactura(codClasificador) {
      let colorState;
      if (codClasificador === 2) {
        colorState = {
          color: 'orange',
          background: 'orange',
        };
      }
      if (codClasificador === 4) {
        colorState = {
          color: 'red',
          background: 'red',
        };
      }
      return colorState;
    },
    ...mapMutations('facturacion', [
      'setShowCortinaInformativa',
    ]),
    ...mapMutations('formaPagoModal', [
      'setFechaCreditoVencimiento',
    ]),
    ...mapActions('facturacion', [
      'callCreateApi',
      'callStoreApi',
      'resetFacturacion',
    ]),
    ...mapActions('formFactura', [
      'resetFormFactura',
    ]),
    ...mapActions('tableAndAddService', [
      'resetTableAndAddService',
    ]),
    ...mapActions('addServiceModal', [
      'resetAddServiceModal',
    ]),
    ...mapActions('formaPagoModal', [
      'resetFormaPagoModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'resetDetalleFactura',
    ]),
    selectMotivoContingencia(data) {
      if (data) {
        this.tipoContingenciaSelected = data;
      }
    },
  },
  computed:
  {
    ...mapFields('main', [
      'extrafacturaElectronica',
    ]),
    ...mapFields('facturacion', [
      'conexionSiat',
    ]),
    ...mapFields('formFactura', [
      'checkSeguroEstado',
      'nroFactura',
      'checkFacturaTercero',
      'indentificacionSelected',
      'monedaSelected',
      'tipoContingenciaSelected',
      'tipoEmisionSelected',
      'medicoSelected',
      'tipoContingencias',
    ]),
    ...mapFields('formaPagoModal', [
      'medioPagoCredito',
      'medioPagoAjusteAnticipo',
    ]),
    ...mapFields('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    ...mapFields('detalleFactura', [
      'totalSeguro',
    ]),
    showCortinaInformativa: {
      set(value) {
        this.setShowCortinaInformativa(value);
      },
      get() {
        return this.getShowCortinaInformativa;
      },
    },
    ...mapGetters('main', [
      'getCompany',
    ]),
    ...mapGetters('facturacion', [
      'getShowCortinaInformativa',
      'getPacienteToShow',
      'getSucursal',
      'getPuntoVentas',
    ]),
    ...mapGetters('formFactura', [
      'getPacienteSelected',
      'isTipoFacturaComputarizada',
      'isTipoFacturaManual',
      'isTipoFacturaComputarizadaEnLinea',
      'isTipoFacturaElectronicaEnLinea',
      'getTipoEmisionsComputed',
    ]),
    ...mapGetters('tableAndAddService', [
      'getListaTabla',
    ]),
    ...mapGetters('formaPagoModal', [
      'isEnabledFormaPago',
      'getMedioPago',
      'getFormaPago',
    ]),
    ...mapGetters('detalleFactura', [
      'getSubTotal',
    ]),
    tipoContigenciasComputed() {
      if (this.checkFacturaTercero) {
        if (this.medicoSelected.configuracion_factura_tercero === 0) {
          return this.medicoSelected.siat_tipo_factura_contingencias;
        }
      }
      return this.tipoContingencias;
    },
    ...mapState('formaPagoModal', [
      'checkCXP',
      'isAjuste',
    ]),
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.bg-yellow-custom {
  background-color: #f9d772;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.label-facturacion-tercero{
  line-height: 1.2;
  font-size: 11px;
}
.en-linea {
  font-size: 18px;
  -webkit-border-radius: 50%;
  background:#3d8b40;
  color:#3d8b40;
  cursor:pointer;
}
.factura-contingencia {
  position: relative;
  top: -80px;
  height: 60px;
  right: 70px;
}
</style>
