<template>
  <div>
    <button
      @click="onClickTurno(id)"
      class="btn px-2"
      :class="getButtonClass"
    >Turno
    </button>
    <b-modal
      :id="'modal-turno-'+id"
      title="Programar Atención"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div class="container row">
          <div class="col-sm-6 pl-0">
            <h5 class="modal-title">Programar Atención</h5>
          </div>
          <div class="col-sm-6 pl-0">
            <button v-if="stateTurno1"
              @click="selectTurno1"
              class="btn mr-3"
              :class="turno === 1? 'btn-success' : 'btn-secondary'"
            >Turno 1</button>
            <button v-if="stateTurno2"
              @click="selectTurno2"
              class="btn"
              :class="turno === 2? 'btn-success' : 'btn-secondary'"
            >Turno 2</button>
          </div>
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="close()"
        >×</button>
      </template>
      <div class="row mb-3">
        <div class="col-sm-3 text-right">
          <label class="col-form-label">Fecha:</label>
        </div>
        <div class="col-sm-3 px-0">
          <date-picker
            class="col-sm-12 px-0"
            v-model="inputFecha"
            @input="onInputFecha"
            :disabled-date="disabledBeforeToday"
            lang="es"
            format="DD/MM/YYYY"
            type="date"
            value-type="date"
          ></date-picker>
        </div>
        <div class="col-sm-3 px-1">
          <input
            :value="horariosDisponibles"
            type="text"
            class="form-control"
            disabled="true"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 text-right">
          <label class="col-form-label">Horario:</label>
        </div>
        <div class="col-sm-4 pl-0">
          <multiselect
            v-model="horarioSelected"
            :options="listaHorarios"
            @input="sendData"
            placeholder=""
            selectedLabel=""
            label="hora"
            track-by="ticket"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(horarioSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">No trabaja</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.ticket}} - {{props.option.hora}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.ticket}} - {{props.option.hora}}</small>
            </template>
          </multiselect>
        </div>
        <div class="col-sm-2" v-if="isDisabledEnfermeria">
          <button class="btn btn-secondary">1° Enfermeria </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import privilegios from '@/plugins/privilegios/index';
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ModalTurno',
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    /**
     * id del modal
     */
    id: {
      type: [Number, String],
      required: true,
    },
    /**
     * indica si la interfaz ventas se encuentra en modo editar
     */
    editMode: {
      type: Boolean,
      required: true,
    },
    turnoFromEdit: {
      type: [Number, String],
      required: false,
      default: null,
    },
    fechaTurnoFromEdit: {
      type: String,
      required: false,
      default: null,
    },
    horaTurnoFromEdit: {
      type: String,
      required: false,
      default: null,
    },
    numeroTicketFromEdit: {
      type: [Number, String],
      required: false,
      default: null,
    },
    /**
     * medico correspondiente, puede ser null.
     */
    medicoSelected: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    async medicoSelected(newVal) {
      this.inputFecha = new Date();
      this.horarioSelected = null;
      this.listaHorarios = [];
      this.turno = 1;
      this.turnoId = null;
      this.sendData();
      if (newVal !== null) {
        await this.getTurnoMedicoStatus();
        this.getDataModal();
      } else {
        this.btnDisabled = true;
      }
    },
  },
  data() {
    return {
      inputFecha: null,
      horarioSelected: null,
      listaHorarios: [],
      turno: 1,
      turnoId: null,
      stateTurno1: false,
      stateTurno2: false,
      btnDisabled: true,
      isDisabledEnfermeria: false,
    };
  },
  mixins: [privilegios],
  async created() {
    if (this.editMode) {
      if (this.turnoFromEdit !== null) {
        this.turno = this.turnoFromEdit;
      }
      if (this.fechaTurnoFromEdit !== null) {
        this.inputFecha = new Date(moment(this.fechaTurnoFromEdit));
      } else {
        this.inputFecha = null;
      }
      if (this.numeroTicketFromEdit !== null && this.horaTurnoFromEdit !== null) {
        this.horarioSelected = {
          ticket: this.numeroTicketFromEdit,
          hora: this.horaTurnoFromEdit,
        };
      }
      if (this.medicoSelected !== null) {
        await this.getTurnoMedicoStatus();
        this.getDataModal();
      }
    }
    if (this.funcionalidadAccions.ENF_LISTAR) {
      const funcionalidadId = this.funcionalidadAccions.ENF_LISTAR.funcionalidad_id;
      const funcionalidadAccion = this.funcionalidadAccions.ENF_LISTAR.accion;
      this.isDisabledEnfermeria = await this.existePrivilegios(funcionalidadId, funcionalidadAccion);
    }
  },
  methods: {
    onClickTurno(index) {
      if (this.btnDisabled === false) {
        this.$bvModal.show(`modal-turno-${index}`);
      }
    },
    async getTurnoMedicoStatus() {
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/configuracion', {
          params: REQUEST,
        });
        const TURNOS_MEDICO = RESPONSE.data.data.turno_medico;
        if (TURNOS_MEDICO.length > 0) {
          const T1 = TURNOS_MEDICO.find((el) => el.numero_turno === 1) ?? null;
          const T2 = TURNOS_MEDICO.find((el) => el.numero_turno === 2) ?? null;
          if (T1 !== null) {
            this.stateTurno1 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno1 = false;
          }
          if (T2 !== null) {
            this.stateTurno2 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno2 = false;
          }
          if (T1 !== null && T2 === null) {
            this.turno = 1;
          }
          if (T1 === null && T2 !== null) {
            this.turno = 2;
          }
        } else {
          this.btnDisabled = true;
          this.stateTurno1 = false;
          this.stateTurno2 = false;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    cargarListaHorarios(lista) {
      const FECHA_SELECCIONADA = moment(this.inputFecha).format('YYYY-MM-DD');
      const FECHA_ACTUAL = moment(new Date()).format('YYYY-MM-DD');
      const HORA_ACTUAL = moment(new Date()).format('HH:mm:ss');
      const TEMP_LIST = [];
      lista.forEach((el) => {
        let estado = false;
        let { hora } = el;
        if (el.hora === 'NO Disponible') {
          estado = true;
        } else if (FECHA_SELECCIONADA < FECHA_ACTUAL) {
          estado = true;
        } else if (HORA_ACTUAL > el.hora && FECHA_SELECCIONADA === FECHA_ACTUAL) {
          estado = true;
        }
        if (this.numeroTicketFromEdit !== null && this.horaTurnoFromEdit !== null) {
          if (el.ticket === this.numeroTicketFromEdit) {
            hora = this.horaTurnoFromEdit;
          }
        }
        const ITEM = {
          $isDisabled: estado,
          ticket: el.ticket,
          hora,
        };
        TEMP_LIST.push(ITEM);
      });
      this.listaHorarios = TEMP_LIST;
    },
    async getDataModal() {
      if (this.medicoSelected === null || this.inputFecha === null) {
        return;
      }
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
          fecha: moment(this.inputFecha).format('YYYY-MM-DD'),
          turno: this.turno,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/', {
          params: REQUEST,
        });
        this.turnoId = RESPONSE.data.data.turno_medico?.id ?? null;
        const TURNOS = RESPONSE.data.data.turno_medico?.turnos ?? [];
        this.cargarListaHorarios(TURNOS);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    async onInputFecha() {
      this.horarioSelected = null;
      this.listaHorarios = [];
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno1() {
      this.turno = 1;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno2() {
      this.turno = 2;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    sendData() {
      let fechaTurno = null;
      if (this.inputFecha !== null) {
        fechaTurno = moment(this.inputFecha).format('YYYY-MM-DD');
      }
      const DATA = {
        fecha_turno: fechaTurno,
        hora_turno: this.horarioSelected?.hora ?? null,
        numero_ticket: this.horarioSelected?.ticket ?? null,
        turno_medico_id: this.turnoId,
      };
      this.$emit('getData', DATA);
    },
  },
  computed: {
    horariosDisponibles() {
      const LISTA_DISPONIBLES = [];
      this.listaHorarios.forEach((el) => {
        if (el.$isDisabled === false) {
          LISTA_DISPONIBLES.push(el);
        }
      });
      return `Disponible: ${LISTA_DISPONIBLES.length}`;
    },
    getButtonClass() {
      if (this.btnDisabled) {
        return 'btn-light';
      }
      return (this.horarioSelected !== null) ? 'btn-success' : 'btn-warning';
    },
    ...mapFields('main', [
      'funcionalidadAccions',
    ]),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
