<template>
  <div>
    <b-modal id="modal-tratamientoEnCurso"
      size='lg'
      title="Tratamientos en Curso"
      body-class="pt-0"
      hide-footer
    >
      <div class="row">
        <div class="col-sm-12 px-0">
          <b-table responsive class="table"
            :fields="fieldsTabla"
            :items="listaTratamientos"
          >
            <template #cell(total)="data">
              {{data.item.total | numeral('0,0.00')}}
            </template>
            <template #cell(anticipo)="data">
              {{data.item.anticipo | numeral('0,0.00')}}
            </template>
            <template #cell(saldo)="data">
              {{data.item.saldo | numeral('0,0.00')}}
            </template>
            <template #cell(accion)="items">
              <div class="col-sm-12">
                <input :id="'checkModal'+items.index"
                  v-model="items.item.checkInput"
                  @input="resetCheckInput(items.item)"
                  class="form-check-input"
                  :disabled="items.item.estado==='Abierto'"
                  type="checkbox"
                >
                <label
                  class="form-check-label" :for="'checkModal'+items.index"
                  style="user-select: none"
                >
                  Seleccionar
                </label>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
            Cancelar
          </button>
          <button class="btn btn-success" @click="confirmarModal()">
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
    <ModalCierreTratamiento />
  </div>
</template>

<script>
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';
import ModalCierreTratamiento from './TheModalCierreTratamiento.vue';

export default {
  name: 'ModalTratamientoEnCurso',
  components: {
    ModalCierreTratamiento,
  },
  data() {
    return {
      fieldsTabla: [
        {
          key: 'numero', label: 'N° Tra.', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'fecha_registro', label: 'F/Registro', thClass: 'table-secondary', thStyle: { width: '13%' },
        },
        {
          key: 'fecha_cierre', label: 'F/Cierre', thClass: 'table-secondary', thStyle: { width: '13%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'anticipo', label: 'Anticipo', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'saldo', label: 'Saldo', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'accion', label: '', thClass: 'table-secondary', thStyle: { width: '18%' },
        },
      ],
    };
  },
  methods: {
    resetCheckInput(item) {
      this.listaTratamientos.forEach((el, index) => {
        if (el.id !== item.id) {
          this.listaTratamientos[index].checkInput = false;
        }
      });
    },
    resetChecks() {
      this.listaTratamientos.forEach((el, index) => {
        this.listaTratamientos[index].checkInput = false;
      });
    },
    cancelarModal() {
      this.resetChecks();
      this.$bvModal.hide('modal-tratamientoEnCurso');
    },
    confirmarModal() {
      const RES = this.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (RES === null) {
        util.showNotify('Seleccione un registro', 'warn');
        return;
      }
      this.$bvModal.hide('modal-tratamientoEnCurso');
      this.$bvModal.show('modal-cierreTratamiento');
    },
  },
  computed: {
    ...mapFields('modalTratamientoEnCurso', [
      'listaTratamientos',
    ]),
  },
};
</script>

<style scoped>
</style>
